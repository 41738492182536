import React, { useEffect, useState } from "react";
import BusCard from "./BusCard";
import SideBar from "../../../components/sidebar/SideBar";
import Grid from "@mui/material/Grid";
import { fetchAllBuses, fetchBusDetails } from "../../../services/busServices";
import Loading from "../../../components/utilities/Loading/Loading";

import { Box } from "@mui/material";

function BusList() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [allBusses, setAllBusses] = useState([]);
  const [selectedBus, setSelectedBus] = useState([]);
  const [loading, setLoading] = useState(true);

  const busData = async () => {
    try {
      setLoading(true);
      const res = await fetchAllBuses();
      const res1 = await fetchBusDetails();

      console.log("->", res1);
      setAllBusses(res);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bus details:", error.message);
    }
  };

  useEffect(() => {
    busData();
  }, []);

  const selectBus = (data) => {
    console.log("bus selected", data);
    setSelectedBus(data);
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        height: "100%",
      })}
    >
      <SideBar userRole={user && user.role}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            {allBusses.map((bus) => (
              <BusCard details={bus} key={bus.id} selectBus={selectBus} />
            ))}
          </Grid>
        )}
      </SideBar>
    </Box>
  );
}

export default BusList;
