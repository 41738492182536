
import { toast } from "react-toastify";
import { signInWithGoogle } from "../../services/authService";

function SignInwithGoogle() {
  const googleLogin = async (e) => {
    try {
      await signInWithGoogle();
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 2000);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
    }
  }
  return (
    <div>
      <p className="continue-p">--Or continue with--</p>
      <div
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
        onClick={googleLogin}
      >
        <img src={require("../../assets/images/google.png")} width={"60%"} />
      </div>
    </div>
  );
}
export default SignInwithGoogle;
