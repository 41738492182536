// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDPo5upu9XqciDo6ulmAUoIqFdMGNTiJ7E",
  authDomain: "bus-booking-metadew.firebaseapp.com",
  projectId: "bus-booking-metadew",
  storageBucket: "bus-booking-metadew.appspot.com",
  messagingSenderId: "505645761775",
  appId: "1:505645761775:web:d383e7ba1756ff39d1c71a",
  measurementId: "G-HHRGHYW46Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth();
export const db=getFirestore(app);
export const storage=getStorage(app);
export default app;