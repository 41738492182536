import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Bus from "../../../assets/images/a.jpg";
import Button from "@mui/material/Button";
import generateView from "../../../components/seatView/generateView";
import "../../../components/seatView/styles.css";
import ButtonB from "@mui/joy/Button";
import JoyCard from "@mui/joy/Card";
import Grid from "@mui/material/Grid";
import { BsBoxFill } from "react-icons/bs";
import SeatPicker from "../../../components/seatView/Config";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BusCard({ details, selectBus }) {
  const [expanded, setExpanded] = React.useState(false);
  const [generatedRows, setGeneratedRows] = React.useState([]);
  const [showGrid, setShowGrid] = React.useState(false);
  const [selected, setSelected] = useState([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setShowGrid(false);
  };

  const addSeatCallback = (row, number, id) => {
    setSelected((prevItems) => [...prevItems, number]);
  };

  const removeSeatCallback = (row, number, id) => {
    setSelected((list) => list.filter((item) => item !== number));
  };

  // console.log(selected);

  return (
    <Card sx={{ maxWidth: 400, margin: 2 }}>
      <CardHeader
        title={`${details.depature_from} - ${details.arrival_to}`}
        subheader={`${details.bus_number} - ${details.bus_type} `}
      />
      <CardMedia component="img" height="194" image={Bus} alt="Paella dish" />

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {details.description}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        <Button
          fullWidth
          style={{ backgroundColor: "rgb(0, 7, 61)" }}
          variant="contained"
          sx={{ mr: 2 }}
          onClick={() => {
            selectBus(details);
            setShowGrid(!showGrid);
            setGeneratedRows(
              generateView(
                details["structure"]["left_seat"] ?? 0,
                details["structure"]["right_seat"] ?? 0,
                details["structure"]["total_rows"] ?? 0,
                [],
                [],
                details["structure"]["removed_seats"] ?? [],
                details["counter_seats"] ?? [],
                1
              )
            );
          }}
        >
          Seat View
        </Button>{" "}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <JoyCard size="sm">Total Seats: {details.total_seats}</JoyCard>
          <JoyCard size="sm">
            Departure Time: {details.depature_time} | Arrival Time:{" "}
            {details.arrival_time}
          </JoyCard>
          <JoyCard size="sm">
            Journey Duration: {details.duration} | Total Distance:{" "}
            {details.distance} KM
          </JoyCard>
          <JoyCard size="sm">
            <Typography>Stops</Typography>
            <ul>
              {console.log(details.route.stops)}
              {details.route.stops
                .sort((a, b) => a.order - b.order)
                .map(
                  (
                    stop,
                    index // Remove the array destructuring here
                  ) => (
                    <li key={index} style={{ listStyle: "none" }}>
                      <ButtonB
                        style={{ minWidth: "200px", marginBottom: "5px" }}
                        size="sm"
                        variant="soft"
                        color="primary"
                        onClick={() => null}
                      >
                        {stop}
                      </ButtonB>
                    </li>
                  )
                )}
            </ul>{" "}
          </JoyCard>
        </CardContent>

        {showGrid ? (
          <Card sx={{ maxWidth: 400, margin: 2, padding: 2 }}>
            <SeatPicker
              addSeatCallback={addSeatCallback}
              removeSeatCallback={removeSeatCallback}
              rows={generatedRows}
              alpha
              maxReservableSeats={5} // This can be a dynamic value from db
              visible
            />{" "}
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <BsBoxFill color="#C65102" /> Counter
              </Grid>
              <Grid item xs={6} sm={4}>
                <BsBoxFill color="#0000FF" /> Male
              </Grid>
              <Grid item xs={6} sm={4}>
                <BsBoxFill color="#FF007F" /> Female
              </Grid>
              <Grid item xs={6} sm={4}>
                <BsBoxFill color="#CCCCCC" /> Available
              </Grid>
              <Grid item xs={6} sm={4}>
                <BsBoxFill color="#4CAF50" /> Selected
              </Grid>
            </Grid>
          </Card>
        ) : (
          ""
        )}
      </Collapse>
    </Card>
  );
}
