import { app_log } from "../../config/logs";

export default function generateView(
    leftSeat,
    rightSeat,
    totalRows,
    reservedSeatsByMale,
    reservedSeatsByFemale,
    removedSeats,
    counterSeats,
    midSpace
) {
    const rows = [];
    let currentId = 1;
    let seatNumber = 1;

    for (let i = 1; i <= totalRows; i++) {
        const row = [];

        for (let j = 1; j <= leftSeat; j++) {
            const seat = { id: currentId, number: seatNumber.toString() };
            if (reservedSeatsByMale.includes(currentId)) {
                seat.isReservedMale = true;
            }
            if (reservedSeatsByFemale.includes(currentId)) {
                seat.isReservedFemale = true;
            }
            if (counterSeats.includes(currentId)) {
                seat.isCounterSeat = true;
            }
            if (removedSeats.includes(currentId)) {
                row.push(null);
            } else {
                row.push(seat);
                seatNumber++;
            }
            currentId++;
        }

        for (let k = 1; k <= midSpace; k++) {
            if (totalRows != i) {
                row.push(null);
            } else {
                const seat = { id: currentId, number: seatNumber.toString() };
                if (reservedSeatsByMale.includes(currentId)) {
                    seat.isReservedMale = true;
                }
                if (reservedSeatsByFemale.includes(currentId)) {
                    seat.isReservedFemale = true;
                }
                if (counterSeats.includes(currentId)) {
                    seat.isCounterSeat = true;
                }
                if (removedSeats.includes(currentId)) {
                    row.push(null);
                } else {
                    row.push(seat);
                    seatNumber++;
                }
                currentId++;
            }
        }

        for (let j = 1; j <= rightSeat; j++) {
            const seat = { id: currentId, number: seatNumber.toString() };
            if (reservedSeatsByMale.includes(currentId)) {
                seat.isReservedMale = true;
            }
            if (reservedSeatsByFemale.includes(currentId)) {
                seat.isReservedFemale = true;
            }
            if (counterSeats.includes(currentId)) {
                seat.isCounterSeat = true;
            }
            if (removedSeats.includes(currentId)) {
                row.push(null);
            } else {
                row.push(seat);
                seatNumber++;
            }
            currentId++;
        }

        rows.push(row);
    }
    // app_log('info', "seat information", "generateView", rows);
    return rows;
}
