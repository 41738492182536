import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Draggable from "react-draggable";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import bill from "../../../assets/images/345.jpg";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { title, numSelected, handleFilterClick } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <React.Fragment>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search by name"
            // value={filterName}
            onChange={handleFilterClick}
            sx={{ marginRight: 1 }}
          />
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EnhancedTable({
  title,
  handleFilterClick,
  headCells = [],
  rows = [],
  searchKey,
  handleAccept,
  handleHold,
  setSelectedRow,
}) {
  // const { handleFilterClick } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [paymentSlip, setPaymentSlip] = React.useState("");
  const [selectedRowData, setSelectedRowData] = React.useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.bookingId);
      setSelected(newSelected);
      console.log(newSelected, selected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, row) => {
    console.log("Row clicked     :", row.id);
    setSelectedRow({ ...row, bus_number: searchKey });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAcceptClick = () => {
    setOpenDialog(false);
    handleAccept();
  };

  const handleHoldClick = () => {
    setOpenDialog(false);
    console.log("Hold");
    handleHold();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <EnhancedTableToolbar
          title={title}
          numSelected={selected.length}
          handleFilterClick={handleFilterClick}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.bookingId);

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleRowClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {headCells.map((headCell) => {
                        const cellValue = row[headCell.id];
                        const cellStyle = headCell.style
                          ? headCell.style(cellValue)
                          : {};
                        const cellContent = headCell.render
                          ? headCell.render(cellValue)
                          : cellValue;

                        if (headCell.clickable === true && headCell.render) {
                          return (
                            <TableCell key={headCell.id} align={"center"}>
                              {cellContent ? (
                                <Button
                                  variant="contained"
                                  style={cellStyle}
                                  onClick={(event) => {
                                    console.log(event);
                                    event.stopPropagation(); // Prevent triggering row click
                                    setOpenDialog(headCell.openDialog);
                                    if (headCell.onClick) {
                                      headCell.onClick(row);
                                    } else {
                                      handleRowClick(event, row);
                                      console.log(row);
                                      setPaymentSlip(row.payment_slip);
                                      setSelectedRowData(row);
                                    }
                                  }}
                                  disabled={headCell.disable(cellValue)}
                                >
                                  {cellContent}
                                </Button>
                              ) : (
                                ""
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? "right" : "left"}
                              style={cellStyle}
                            >
                              {cellContent}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {paymentSlip != "" ? (
              <img
                src={paymentSlip}
                style={{ width: "400px" }}
                alt="bill"
              ></img>
            ) : (
              "No bill exist!"
            )}
            <DialogContentText
              sx={{
                marginTop: 1,
                flexDirection: "column",
                display: "flex",
                alignSelf: "baseline",
              }}
            >
              <Typography color={"black"} variant="body1">
                Total Amount: {selectedRowData.fee}.00
              </Typography>
              <Typography color={"black"} variant="body1">
                Name : {selectedRowData.passenger_name}
              </Typography>
              <Typography color={"black"} variant="body1">
                NIC : {selectedRowData.nic}
              </Typography>
              <Typography color={"black"} variant="body1">
                Bank :BOC
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAcceptClick}
              variant="contained"
              style={{ background: "green" }}
            >
              Approve
            </Button>
            <Button
              onClick={handleHoldClick}
              variant="contained"
              style={{ background: "orange" }}
            >
              Hold
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              style={{ background: "red" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              margin: 0,
            },
            "& .MuiTablePagination-displayedRows": { margin: 0 },
          }}
        />
      </Paper>
    </Box>
  );
}
