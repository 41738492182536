import { auth, db } from "../config/firebase";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { app_log } from "../config/logs";

export const fetchBusDetails = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const docRef = doc(db, "bus", "ND4564");
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const busData = docSnap.data();

            console.log(busData);

            // Accessing reference data
            const seatStructureRef = busData.structure;
            const routeRef = busData.route;

            // Fetching structure data
            const seatStructureSnap = await getDoc(seatStructureRef);
            const seatStructureData = seatStructureSnap.data();

            // Fetching route data
            const routeSnap = await getDoc(routeRef);
            const routeData = routeSnap.data();

            const busDetails = {
              ...busData,
              structure: seatStructureData,
              route: routeData,
            };

            // app_log("info", "Bus Data", "fetchBusDetails", busDetails);
            resolve(busDetails);
          } else {
            // app_log("error", "Bus data not found", "fetchBusDetails");
            resolve(null);
          }
        } else {
          // app_log("error", "User is not logged in", "fetchBusDetails");
          resolve(null);
        }
      } catch (error) {
        // app_log("error", "Error fetching user details:", error.message);
        reject(error);
      }
    });
  });
};

export const fetchAllBuses = async () => {
  return new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged(async (user) => {
        try {
          const busesCollectionRef = collection(db, "bus");
          // const busesSnapshot = await getDocs(busesCollectionRef);
          const busesSnapshot = await getDocs(
            query(busesCollectionRef, where("status", "==", true))
          );
          var allBuses = [];

          await Promise.all(
            busesSnapshot.docs.map(async (busDoc) => {
              const busData = busDoc.data();

              const seatStructureRef = busData.structure;
              const routeRef = busData.route;

              const seatStructureSnap = await getDoc(seatStructureRef);
              const seatStructureData = seatStructureSnap.data();

              const routeSnap = await getDoc(routeRef);
              const routeData = routeSnap.data();

              const busDetails = {
                ...busData,
                id: busDoc.id,
                structure: seatStructureData,
                route: routeData,
              };
              allBuses.push(busDetails);
            })
          );

          console.log(allBuses);
          // app_log("info", "busDetails", "fetchAllBuses", allBuses);
          resolve(allBuses); // Resolve with the data
        } catch (error) {
          // app_log("error", "fetchAllBuses", error.message);
          reject(error); // Reject if an error occurs
        }
      });
    } catch (error) {
      // app_log("error", "Error fetching user details:", error.message);
      reject(error); // Reject if an error occurs
    }
  });
};

export const searchBusRoute1 = async (pickUp, dropping, formattedDate) => {
  try {
    // app_log("info", "Init", "searchBusRoute");

    const pickUpPoint = pickUp;
    const droppingPoint = dropping;

    const collectionRef = collection(db, "bus_stops");
    const querySnapshot = await getDocs(
      query(
        collectionRef,
        where("status", "==", true),
        where("stops", "array-contains", droppingPoint)
      )
    );

    const searchedRoutes = [];
    const filteredData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter(
        (obj) =>
          obj.stops.indexOf(pickUpPoint) < obj.stops.indexOf(droppingPoint) &&
          obj.stops.includes(pickUpPoint)
      );

    filteredData.forEach((item) => {
      searchedRoutes.push(item.id);
    });

    // app_log(
    //   "info",
    //   "searchedRoutes - " + pickUpPoint + " to " + droppingPoint,
    //   " searchBusRoute",
    //   searchedRoutes
    // );

    const buses = [];
    for (const route of searchedRoutes) {
      const busSnapshot = await getDocs(
        query(collection(db, "bus"), where("route_path", "==", route))
      );
      busSnapshot.forEach((busDoc) => {
        const busData = busDoc.data();
        console.log(busData);
        buses.push({ id: busDoc.id, ...busData });
      });
    }

    const busDetailsWithSeatStructure = [];
    for (const bus of buses) {
      const seatStructureDoc = await getDoc(doc(db, bus.structure.path));
      const seatStructureData = seatStructureDoc.data();

      const routeDoc = await getDoc(doc(db, bus.route.path));
      const routeDocData = routeDoc.data();

      delete bus.route;
      delete bus.structure;

      busDetailsWithSeatStructure.push({
        ...bus,
        structure: seatStructureData,
        routeSet: routeDocData,
      });
    }

    console.log(busDetailsWithSeatStructure);

    // app_log(
    //   "info",
    //   "searchedRoutes - " + pickUpPoint + " to" + droppingPoint,
    //   "searchBusRoute :: busDetailsWithSeatStructure",
    //   busDetailsWithSeatStructure
    // );

    return busDetailsWithSeatStructure;
  } catch (error) {
    // app_log("error", "Error fetching bus details:", error.message);
  }
};

export const getLocationList = async () => {
  try {
    const docRef = doc(db, "config", "location_list");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const locationData = docSnap.data();

      // app_log("info", "Location Data", "getLocationList", locationData);
      return locationData;
    } else {
      // app_log("error", "Location data not found", "getLocationList");
      return null;
    }
  } catch (error) {
    // app_log("error", "Error fetching location details:", error.message);
    throw error;
  }
};

export const searchBusRoute = async (pickUp, dropping, formattedDate) => {
  try {
    // app_log("info", "Init", "searchBusRoute");

    const pickUpPoint = pickUp;
    const droppingPoint = dropping;

    const collectionRef = collection(db, "bus_stops");
    const querySnapshot = await getDocs(
      query(
        collectionRef,
        where("status", "==", true),
        where("stops", "array-contains", droppingPoint)
      )
    );

    const searchedRoutes = [];
    const filteredData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter(
        (obj) =>
          obj.stops.indexOf(pickUpPoint) < obj.stops.indexOf(droppingPoint) &&
          obj.stops.includes(pickUpPoint)
      );

    filteredData.forEach((item) => {
      searchedRoutes.push(item.id);
    });

    // app_log(
    //   "info",
    //   "searchedRoutes - " + pickUpPoint + " to " + droppingPoint,
    //   " searchBusRoute",
    //   searchedRoutes
    // );

    const buses = [];
    for (const route of searchedRoutes) {
      const busSnapshot = await getDocs(
        query(collection(db, "bus"), where("route_path", "==", route))
      );
      busSnapshot.forEach((busDoc) => {
        const busData = busDoc.data();
        console.log(busData);
        buses.push({ id: busDoc.id, ...busData });
      });
    }
    const busDetailsWithSeatStructure = [];

    for (const bus of buses) {
      const seatStructureDoc = await getDoc(doc(db, bus.structure.path));
      const seatStructureData = seatStructureDoc.data();

      const routeDoc = await getDoc(doc(db, bus.route.path));
      const routeDocData = routeDoc.data();

      const bookingsCollectionRef = collection(db, "bus", bus.id, "bookings");
      const bookingSnapshot = await getDocs(
        query(bookingsCollectionRef, where("date", "==", formattedDate))
      );
      let bookingsData = bookingSnapshot.docs.map((doc) => doc.data());
      console.log(bookingsData)

      bookingsData = bookingsData.filter(booking => booking.status !== "hold");

      console.log(bookingsData)

      let reservedSeatsByMale = [];
      let reservedSeatsByFemale = [];
      
      if (bookingsData.length > 0) {
        const { reservedSeatsByMale: maleSeats, reservedSeatsByFemale: femaleSeats } = formatBookings(bookingsData);
        reservedSeatsByMale = maleSeats;
        reservedSeatsByFemale = femaleSeats;
      }

      delete bus.route;
      delete bus.structure;

      // Append booking data and other details to the bus object
      busDetailsWithSeatStructure.push({
        ...bus,
        structure: seatStructureData,
        routeSet: routeDocData,
        bookings: bookingsData,
        reservedSeatsByMale,
        reservedSeatsByFemale,
      });
    }

    console.log(busDetailsWithSeatStructure);

    // app_log(
    //   "info",
    //   "Complete data for routes from " + pickUpPoint + " to " + droppingPoint,
    //   "searchBusRoute :: busDetailsWithSeatStructure",
    //   busDetailsWithSeatStructure
    // );

    return busDetailsWithSeatStructure;
  } catch (error) {
    // app_log("error", "Error fetching bus details:", error.message);
  }
};

const formatBookings = (bookings) => {
  let reservedSeatsByMale = [];
  let reservedSeatsByFemale = [];

  console.log("formatBookings")

  bookings.forEach((booking) => {
    if (booking.seats) {
      Object.entries(booking.seats[0]).forEach(([seatNumber, gender]) => {
        if (gender.toLowerCase() === "male") {
          reservedSeatsByMale.push(parseInt(seatNumber));
        } else if (gender.toLowerCase() === "female") {
          reservedSeatsByFemale.push(parseInt(seatNumber));
        }
      });
    }
  });

  return { reservedSeatsByMale, reservedSeatsByFemale };
};

export const saveBooking = async (bookingDetails) => {
  try {
    // Assume bookingDetails contains bus_number to identify which bus collection to save to
    const { bus_number } = bookingDetails;

    if (!bus_number) {
      throw new Error("Bus number must be specified in the booking details.");
    }

    // Access the specific bus's bookings collection
    const bookingsRef = collection(db, "bus", bus_number, "bookings");

    // Add the booking to Firestore
    const bookingRef = await addDoc(bookingsRef, bookingDetails);
    // app_log("info", "Booking saved", "saveBooking", {
    //   id: bookingRef.id,
    //   ...bookingDetails,
    // });
    return {
      success: true,
      message: "Booking successfully saved",
      bookingId: bookingRef.id,
    };
  } catch (error) {
    // app_log("error", "Failed to save booking", "saveBooking", error.message);
    return {
      success: false,
      message: error.message,
    };
  }
};

export const fetchBooking = async (bus_number, date) => {
  return new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged(async (user) => {
        try {
          const bookingsCollectionRef = collection(
            db,
            "bus",
            bus_number,
            "bookings"
          );
          const bookingSnapshot = await getDocs(
            query(bookingsCollectionRef, where("date", "==", date))
          );

          const bookings = bookingSnapshot.docs.map((bookingDoc) => ({
            ...bookingDoc.data(),
            id: bookingDoc.id,
          }));

          console.log(bookings);
          // let filteredBookings = bookings.filter(booking => booking.status !== "hold");
          // app_log("info", "busBooking", "fetchBooking", bookings);
          resolve(bookings); // Resolve with the data
        } catch (error) {
          // app_log("error", "fetchBooking", error.message);
          reject(error); // Reject if an error occurs
        }
      });
    } catch (error) {
      // app_log("error", "Error fetching booking details:", error.message);
      reject(error); // Reject if an error occurs
    }
  });
};

export const updateBookings = async (booking) => {
  console.log("in Bus service :: ", booking);
  return new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged(async (user) => {
        try {
          const bookingDocRef = doc(
            db,
            "bus",
            booking.bus_number,
            "bookings",
            booking.id
          );

          await updateDoc(bookingDocRef, booking);
          // console.log("Booking updated successfully");
          resolve("success");
        } catch (error) {
          // app_log("error", "fetchBooking", error.message);
          reject(error); // Reject if an error occurs
        }
      });
    } catch (error) {
      // app_log("error", "Error updating booking details:", error.message);
      reject(error); // Reject if an error occurs
    }
  });
};

export const getUniqueBookingsByNIC = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const busesCollectionRef = collection(db, "bus");
      const busesSnapshot = await getDocs(busesCollectionRef);

      const uniqueBookings = {};

      await Promise.all(
        busesSnapshot.docs.map(async (busDoc) => {
          const bookingsCollectionRef = collection(
            db,
            "bus",
            busDoc.id,
            "bookings"
          );
          const bookingSnapshot = await getDocs(bookingsCollectionRef);

          bookingSnapshot.forEach((bookingDoc) => {
            const bookingData = bookingDoc.data();
            const { nic } = bookingData;

            if (nic) {
              if (uniqueBookings[nic]) {
                uniqueBookings[nic].bookings++;
              } else {
                uniqueBookings[nic] = {
                  name: bookingData.passenger_name,
                  bookings: 1,
                  nic: nic,
                  mobile_no: bookingData.mobile_no,
                };
              }
            }
          });
        })
      );

      const result = Object.values(uniqueBookings);
      console.log(result);
      resolve(result);
    } catch (error) {
      console.error("Error fetching unique bookings:", error.message);
      reject(error);
    }
  });
};



export const getTotalBookingsForToday = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const busesCollectionRef = collection(db, "bus");
      const busesSnapshot = await getDocs(busesCollectionRef);

      const today = new Date().toISOString().slice(0, 10); // Get today's date in "YYYY-MM-DD" format

      let totalBookings = 0;

      await Promise.all(
        busesSnapshot.docs.map(async (busDoc) => {
          const bookingsCollectionRef = collection(db, "bus", busDoc.id, "bookings");
          const bookingSnapshot = await getDocs(bookingsCollectionRef);

          bookingSnapshot.forEach((bookingDoc) => {
            const bookingData = bookingDoc.data();
            const { date } = bookingData;

            // Check if the booking is for today
            if (date === today) {
              totalBookings++;
            }
          });
        })
      );

      console.log("Total bookings for today:", totalBookings);
      resolve(totalBookings);
    } catch (error) {
      console.error("Error fetching total bookings for today:", error.message);
      reject(error);
    }
  });
};