import { app_log } from "../../config/logs";

export default function validateSeatSelectionNew(
  type,
  selected,
  reservedSeatsByFemale,
  reservedSeatsByMale
) {
  // app_log("info", "seat information", "validateSeatSelection", {
  //   selected,
  //   type,
  // });
  var error = false;
  var errorMessage = "";

  // For 2X2 Seat View
  if (type == "male") {
    reservedSeatsByFemale.forEach((item) => {
      if (item % 2 == 0) {
        if (selected.includes((parseInt(item) - 1).toString())) {
          error = true;
          errorMessage = "Male you can't select seat near to the female";
        }
      } else {
        if (selected.includes((parseInt(item) + 1).toString())) {
          error = true;
          errorMessage = "Male you can't select seat near to the female";
        }
      }
    });
  } else if (type == "female") {
    reservedSeatsByMale.forEach((item) => {
      if (item % 2 == 0) {
        if (selected.includes((parseInt(item) - 1).toString())) {
          error = true;
          errorMessage = "Female you can't select seat near to the male";
        }
      } else {
        if (selected.includes((parseInt(item) + 1).toString())) {
          error = true;
          errorMessage = "Female you can't select seat near to the male";
        }
      }
    });
  }

  if (error) {
    // app_log("error", "", errorMessage);
    return false;
  } else {
    // app_log("success", "", "Booking success");
    return true;
  }
}
