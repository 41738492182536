import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const BusContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '80px', // Adjusted for responsiveness
  height: '40px', // Adjusted for responsiveness
  margin: 'auto',
  '& .bus': {
    width: '100%',
    height: '100%',
    background: '#007bff',
    borderRadius: '10px',
    position: 'absolute',
    bottom: 0,
    animation: 'busAnimation 2s linear infinite',
    '&::before': {
      content: "''",
      display: 'block',
      width: '15px', // Adjusted for responsiveness
      height: '15px', // Adjusted for responsiveness
      background: '#007bff',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-7px', // Adjusted for responsiveness
      left: '7px', // Adjusted for responsiveness
    },
    '&::after': {
      content: "''",
      display: 'block',
      width: '15px', // Adjusted for responsiveness
      height: '15px', // Adjusted for responsiveness
      background: '#007bff',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-7px', // Adjusted for responsiveness
      right: '7px', // Adjusted for responsiveness
    },
  },
}));

const BusAnimation = `
  @keyframes busAnimation {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
`;

export default function Loading() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <style>
        {BusAnimation}
      </style>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          textAlign: 'center',
        }}
      >
        <BusContainer>
          <Box className="bus"></Box>
        </BusContainer>
        <Typography variant="body1" color="inherit" sx={{ fontSize: { xs: 'body2.fontSize', sm: 'h6.fontSize' } }}>
          Loading, please wait...
        </Typography>
        {/* <CircularProgress color="inherit" /> */}
      </Box>
    </Backdrop>
  );
}
