import React, { useEffect, useState } from "react";
import SideBar from "../../../components/sidebar/SideBar";
import { Box, Container } from "@mui/material";
import Loading from "../../../components/utilities/Loading/Loading";
import SeatView from "../../../components/seatView/SeatView";
import { auth } from "../../../config/firebase";

const item = {
  depature_time: "06.30 PM",
  route_path: "colombo_welimada",
  counter_seats: [1, 2, 3, 4, 47],
  route: {
    status: true,
    stops: ["Colombo", "Kirulapone", "Nugegoda", "Rathnapura", "Welimada"],
  },
  duration: "6h 30m",
  bus_number: "ND4564",
  total_seats: 49,
  status: true,
  bus_type: "Super Luxury",
  depature_from: "Colombo",
  structure: {
    total_rows: 12,
    right_seat: 2,
    layout: "2X2",
    left_seat: 2,
    removed_seats: [],
    mid_space: 1,
  },
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
  fee: "1200",
  arrival_to: "Welimada",
  distance: 400,
  arrival_time: "10.30 AM",
  paid: true,
};
function BusInfo({ withSideBar }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        height: "100%",
      })}
    >
      <SideBar userRole={user && user.role}>
        {loading ? (
          <Loading />
        ) : (
          <Container sx={{ width: "100%", height: "100vh" }}>
            <SeatView item={item} userMode={false} />
          </Container>
        )}
      </SideBar>
    </Box>
  );
}

export default BusInfo;
