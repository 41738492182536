import React from "react";
import SideBar from "../sidebar/SideBar";
import { ToastContainer } from "react-toastify";

function Passward() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <SideBar Bar userRole={user && user.role}>
      {/* :TODO:Add the userRole */}
      <ToastContainer />
      Passward {"  Name: "}
      {user.firstName}
    </SideBar>
  );
}

export default Passward;
