import * as React from "react";
import { Box, Container, Typography, Grid, Card } from "@mui/material";

// image imports
import aboutImage from "../../assets/images/about.jpg";
import placeholderImage from "../../assets/images/about.jpg";
import C5C_5429 from "../../assets/images/C5C_5529.jpg"; 
import C5C_5420 from "../../assets/images/C5C_5520.jpg"; 
import C5C_5425 from "../../assets/images/C5C_5525.jpg"; 
import C5C_5546 from "../../assets/images/C5C_5546.jpg";
import C5C_5558 from "../../assets/images/C5C_5558.jpg"; 
import C5C_5508 from "../../assets/images/C5C_5508.jpg"; 
import C5C_5556 from "../../assets/images/C5C_5556.jpg"; 
import C5C_5510 from "../../assets/images/C5C_5510.jpg"; 
import C5C_5514 from "../../assets/images/C5C_5514.jpg"; 
import C5C_5543 from "../../assets/images/C5C_5543.jpg"; 

export default function About() {
  return (
    <Box
      id="about"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 90% 90% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container id="features" sx={{ py: { xs: 15, sm: 16 } }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <div>
              <Typography 
                component="h2" 
                variant="h2" 
                sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, 
                  mb: 2 
                }}
              >
                Know Few Words About
              </Typography>
              <Typography 
                component="h2" 
                variant="h2" 
                color="blue[100]"
                sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, 
                  mb: 2 
                }}
              >
                VISHMITHA HIGHWAY EXPRESS
              </Typography>
              <Typography
                component="h5"
                variant="h5"
                sx={{ my: { xs: 2, sm: 2, md: 2 } }}
              >
                {/* Add additional information if needed */}
              </Typography>
              <Typography variant="body1" sx={{ mb: { xs: 2, sm: 4 } }}>
                Vishmitha HighWay offers a seamless booking service for
                long-distance travelers between Badulla and Colombo. With our
                service, passengers can easily book bus seats for this route
                from one convenient platform. Currently, many travelers face
                difficulties in planning their journeys due to the unpredictable
                departure times of private bus operators. Vishmitha HighWay
                solves this problem by providing a detailed system that includes
                information on bus routes, departure times, bus numbers, and
                fares. This ensures that our online booking users are
                well-informed about their travel details. Our platform lists all
                Vishmitha HighWay schedules and destinations along the Badulla
                to Colombo route, allowing passengers to plan their trips with
                confidence. Additionally, we monitor passenger boarding points
                to ensure smooth operations and a reliable travel experience.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", width: "100%" }}
          >
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: 500,
                  backgroundSize: "cover",
                  backgroundPosition: "right",
                  backgroundImage: `url(${C5C_5556})`,
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Additional Images and Text Sections */}
      <Container sx={{ py: { xs: 10, sm: 12 } }}>
        <Typography 
          sx={{ 
            mb: 4, 
            textAlign: 'center',
            fontWeight: 'bold', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } 
          }}
        >
          Safe Journey
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: { xs: 2, sm: 4 } }}>
              At Vishmitha HighWay, we are dedicated to providing the best travel
              experience. Our modern fleet is equipped with amenities that ensure
              comfort and convenience throughout the journey. We prioritize
              safety and punctuality, making sure that our buses run on time and
              meet high safety standards. Whether you're traveling for business
              or leisure, our booking system allows you to plan your trip
              efficiently and effortlessly.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5558})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5546})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5508})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: { xs: 2, sm: 4 } }}>
              We understand the importance of a smooth and hassle-free travel
              experience. Our team is committed to ensuring that every aspect of
              your journey is handled with care. From booking your ticket to
              arriving at your destination, Vishmitha HighWay aims to provide a
              service that meets your expectations and exceeds them. Trust us to
              make your travel experience memorable and enjoyable.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Additional Images */}
      <Container sx={{ py: { xs: 10, sm: 12 } }}>
        <Typography 
          sx={{ 
            mb: 1, 
            textAlign: 'center',
            fontWeight: 'bold', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } 
          }}
        >
          Comfort on the Go
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5425})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5420})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5429})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: { xs: 2, sm: 4 } }}>
              Our commitment to excellence is reflected in every aspect of our
              service. With a focus on customer satisfaction and operational
              efficiency, Vishmitha HighWay stands as a trusted choice for long-distance
              travel. Experience the difference with us and make your journey
              comfortable and reliable.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Additional Images */}
      <Container sx={{ py: { xs: 10, sm: 12 } }}>
        <Typography 
          sx={{ 
            mb: 1, 
            textAlign: 'center',
            fontWeight: 'bold', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } 
          }}
        >
          Journey Beyond Limits
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5543})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5514})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${C5C_5510})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: { xs: 2, sm: 4 } }}>
              Our commitment to excellence is reflected in every aspect of our
              service. With a focus on customer satisfaction and operational
              efficiency, Vishmitha HighWay stands as a trusted choice for long-distance
              travel. Experience the difference with us and make your journey
              comfortable and reliable.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
