import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const rows = [
  {
    bookingId: "B123456",
    date: "2024-05-01",
    busNumber: "AB123",
    departure: "New York",
    arrival: "Washington",
    seatNumber: 12,
    passengerName: "John Doe",
  },
  {
    bookingId: "B123457",
    date: "2024-05-02",
    busNumber: "CD456",
    departure: "Los Angeles",
    arrival: "San Francisco",
    seatNumber: 7,
    passengerName: "Jane Smith",
  },
  {
    bookingId: "B123458",
    date: "2024-05-03",
    busNumber: "EF789",
    departure: "Chicago",
    arrival: "Detroit",
    seatNumber: 15,
    passengerName: "Alice Johnson",
  },
  {
    bookingId: "B123459",
    date: "2024-05-04",
    busNumber: "GH012",
    departure: "Houston",
    arrival: "Dallas",
    seatNumber: 22,
    passengerName: "Bob Brown",
  },
  {
    bookingId: "B123460",
    date: "2024-05-05",
    busNumber: "IJ345",
    departure: "Miami",
    arrival: "Orlando",
    seatNumber: 9,
    passengerName: "Carol White",
  },
  {
    bookingId: "B123461",
    date: "2024-05-06",
    busNumber: "KL678",
    departure: "Seattle",
    arrival: "Portland",
    seatNumber: 10,
    passengerName: "David Clark",
  },
];

export default function BookingCard() {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {rows.map((row, index) => (
        <React.Fragment key={index}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={row.passengerName}
                src={`/static/images/avatar/${index + 1}.jpg`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={row.bookingId}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {row.passengerName}
                  </Typography>
                  {` - From ${row.departure} to ${row.arrival}, Seat Number: ${row.seatNumber}`}
                </React.Fragment>
              }
            />
          </ListItem>
          {index !== rows.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
