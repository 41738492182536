import React from "react";
import SideBar from "../sidebar/SideBar";
import { ToastContainer } from "react-toastify";
import { Grid, Card, Typography } from "@mui/material";
import BookingCard from "../../views/admin/Bookings/BookingCard";

function Report() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <SideBar userRole={user && user.role}>
      {/* :TODO:Add the userRole */}
      <ToastContainer />

      <Grid container sx={{ height: "100vh" }}>
        <Grid xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card className="booking-section" sx={{ padding: 1 }}>
            <Typography variant="h4">Previous Bookings</Typography>
            <BookingCard />
          </Card>
        </Grid>
        <Grid xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card className="booking-section" sx={{ padding: 1 }}>
            <Typography variant="h4">Today's Bookings</Typography>
            <BookingCard />
          </Card>
        </Grid>
        <Grid xs={12} md={4} sx={{ width: "100%", padding: 1 }}>
          <Card className="booking-section" sx={{ padding: 1 }}>
            <Typography variant="h4">Upcoming Bookings</Typography>
            <BookingCard />
          </Card>
        </Grid>
      </Grid>
    </SideBar>
  );
}

export default Report;
