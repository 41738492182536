import React, { useState } from "react";
import {
  Box,
  Button,
  StepButton,
  Container,
  Step,
  Stepper,
  Typography,
  Card,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SeatView from "./SeatView";
import { BsBoxFill } from "react-icons/bs";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const steps = ["Select Your Seats", "Your Details", "Payment method"];

export default function HorizontalNonLinearStepper({
  item,
  pickup,
  drop,
  date,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [paymentType, setPaymentType] = useState("");

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handlePaymentTypeChanged = (event) => {
    setPaymentType(event.target.value);
  };

  console.log("i Am here", item);

  const callChildFunction = () => {
    // Access the reference to the function passed from the child component
    // Call the function defined in the child component
    console.log("Calling child function from parent component");
    // Here, you can call the function as needed
  };

  // Assuming date is the dynamic date object you provided
  const datezz = new Date(date);
  const year = datezz.getFullYear();
  const month = String(datezz.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because it is zero-based
  const day = String(datezz.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 18 },
          pb: { xs: 1, sm: 12, md: 4 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
          }}
        >
          Book Your Seat
        </Typography>

        <Card
          id="image"
          sx={{
            width: "100%",
            mt: 4,
            pt: { xs: 2, sm: 3 },
            p: 3,
          }}
        >
          {/* <Stepper nonLinear activeStep={activeStep} sx={{ my: 3, mx: 2 }}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper> */}
          <Grid
            container
            sx={{
              flexDirection: {
                xs: "column", // Column on extra-small and small screens
                md: "row", // Row on medium and larger screens
              },
            }}
          >
            <Grid item md={4}>
              <Card sx={{ p:2 , mr: 2, mb: 2 }} elevation={24}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  useFlexGap
                  sx={{ width: "100%", display: { xs: "flex" } }}
                >
                  <Stack
                    direction="raw"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography color="text.secondary" variant="body1">
                      Bus No:
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {item.bus_number}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="raw"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography color="text.secondary" variant="body1">
                      Journey Date:
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {formattedDate}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="raw"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography color="text.secondary" variant="body1">
                      Pickup Point:
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {pickup}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="raw"
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography color="text.secondary" variant="body1">
                      Dropping Point:
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {drop}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
              <Grid container spacing={1} pt={2}>
                <Grid item xs={6} sm={4}>
                  <BsBoxFill color="#C65102" /> Counter
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BsBoxFill color="#0000FF" /> Male
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BsBoxFill color="#FF007F" /> Female
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BsBoxFill color="#CCCCCC" /> Available
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BsBoxFill color="#4CAF50" /> Selected
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <Grid container spacing={2} direction="column">
                      {" "}
                      {/* Change direction to column for smaller screens */}
                      <Grid item xs={12}>
                        <SeatView item={item} userMode={true} 
                         pickup={pickup}
                         drop={drop}
                         date={formattedDate}/>
                      </Grid>
                    </Grid>
                  ) : activeStep === 1 ? (
                    <Grid container spacing={2} direction="column">
                      {" "}
                      {/* Change direction to column for smaller screens */}
                      <Grid item xs={12}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Form to Enter Details
                          <li>Name</li>
                          <li>Mobile Number</li>
                          <li>Email</li>
                          <li>NIC or Passport</li>
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          component="fieldset"
                          style={{ margin: "20px" }}
                        >
                          <FormLabel component="legend"></FormLabel>
                          <RadioGroup
                            aria-label="Payment Type"
                            name="paymentType"
                            value={paymentType}
                            onChange={handlePaymentTypeChanged}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "10px",
                            }}
                          >
                            <Grid item xs={12}>
                              <FormControlLabel
                                value="pay_in_bus"
                                control={<Radio />}
                                label="Pay in Bus"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {" "}
                              <FormControlLabel
                                value="upload_slip"
                                control={<Radio />}
                                label="Bank Transfer (Upload Slip)"
                              />
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {paymentType == "upload_slip" && (
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            Form to Upload Slip
                            <ui>
                              <li>Name</li>
                              <li>Account Number</li>
                              <li>Bank</li>
                              <li>Branch</li>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {}}
                                style={{ marginTop: "20px" }}
                              >
                                Upload
                              </Button>
                            </ui>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleNext} sx={{ mr: 1 }}>
                      Next
                    </Button>
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <Button onClick={handleComplete}>
                          {completedSteps() === totalSteps() - 1
                            ? "Finish"
                            : "Complete Step"}
                        </Button>
                      ))}
                  </Box> */}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}
