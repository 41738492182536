import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

// Image imports
import contactImage1 from "../../assets/images/C5C_5551.jpg";
import contactImage2 from "../../assets/images/C5C_5520.jpg";
import contactImage3 from "../../assets/images/C5C_5514.jpg";

const cards = [
  {
    icon: <LocationOnIcon />,
    title: "Our Address",
    value: "Address : 246/6B, Kumarasinghe Road, Badulla",
  },
  {
    icon: <LocalPhoneIcon />,
    title: "Call Us",
    value: "+94 766333030 | +94 775333030",
  },
  {
    icon: <EmailIcon />,
    title: "Email Us",
    value: "info@vishmitha.lk",
  },
];

export default function Contact() {
  return (
    <Box
      id="contact"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 90% 90% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 18 },
          pb: { xs: 1, sm: 12, md: 0 },
          mb: 0,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
          }}
        >
          Contact
        </Typography>

        <Container
          id="testimonials"
          sx={{
            pt: { xs: 4, sm: 12, md: 4 },
            pb: { xs: 8, sm: 16, md: 0 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography component="h2" variant="h4" color="text.primary">
              Let's get in touch
            </Typography>
            <Typography variant="body1" color="text.secondary">
              We are open for any suggestion or just to have a chat
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {cards.map((card, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{ display: "flex" }}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      pr: 2,
                    }}
                  >
                    <CardHeader avatar={card.icon} title={card.title} />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {card.value}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>

      {/* Additional Images Section */}
      <Container sx={{ py: { xs: 10, sm: 12 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${contactImage1})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${contactImage2})`,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${contactImage3})`,
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
