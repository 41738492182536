import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../config/firebase";
import { fetchuserDetails } from "../../services/userService";
import CircularProgress from "@mui/material/CircularProgress";
import loginBackground from "../../assets/images/about.jpg";
import logo from "../../assets/images/logo.jpg";
import SignInwithGoogle from "./signInWIthGoogle"; // Assuming Google Sign-In is optional

const defaultTheme = createTheme();

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State for loading
  const [userDetails, setUserDetails] = useState(() => {
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue || { role: "both" };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in Successfully", userCredential);
      const userDetails = await fetchuserDetails();
      setUserDetails(userDetails);
      localStorage.setItem("user", JSON.stringify(userDetails));
      console.log(userDetails);
      setTimeout(() => {
        if (userDetails.role === "admin") {
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/bookings";
        }
      }, 2000);
    } catch (error) {
      console.error("Authentication Error");
      toast.error(error.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card variant="none" sx={{ mb: 3 }}>
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "100%",
                  maxWidth: 350,
                  height: "auto",
                  paddingBottom: 10,
                }}
              />
            </Card>

            <Box
              component="form"
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                type="submit"
                disabled={loading} // Disable button while loading
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
              </Button>
              {/* <SignInwithGoogle /> */}
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                    {/* TODO: add the forgot password */}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
