import React, { useEffect, useState } from "react";
import SideBar from "../sidebar/SideBar";
import { fetchuserDetails } from "../../services/userService";
import Loading from "../utilities/Loading/Loading";
import {
  Container,
  Avatar,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  FormControl,
  Button,
  TextField,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

function Profile() {
  // const [userDetails, setUserDetails] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [email, setEmail] = useState(userDetails.email);
  const [name, setName] = useState(userDetails.firstName);
  // const loggedUser = async () => {
  //   try {
  //     const userDetails = await fetchuserDetails();
  //     setUserDetails(userDetails);

  //     setEmail(userDetails.email);
  //     setName(userDetails.firstName);
  //   } catch (error) {
  //     console.error("Error fetching user details:", error.message);
  //   }
  // };
  // useEffect(() => {
  //   loggedUser();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Info Edited");
    toast.success("User data update is Successfully", {
      position: "top-right",
    });
  };

  return (
    <SideBar userRole={userDetails.role}>
      <ToastContainer />
      <div style={{ height: "100vh", width: "100%" }}>
        {userDetails ? (
          <>
            <Container>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: 2,
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  sx={{
                    width: 200,
                    height: 200,
                    borderStyle: "solid",
                    borderColor: "lightblue",
                    marginTop: 3,
                  }}
                  src={userDetails.photo}
                />
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ margin: "normal" }} fullWidth>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoFocus
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ margin: "normal" }} fullWidth>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Update
                    </Button>{" "}
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </>
        ) : (
          <p>
            <Loading />
          </p>
        )}
      </div>
    </SideBar>
  );
}
export default Profile;
