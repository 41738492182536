import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBar from "../sidebar/SideBar";

import "./profile.css"; // Assuming you have some custom styling here
import { auth } from "../../config/firebase";
import Dash from "./Dash";
import BusInfo from "../../views/admin/BusCard/BusInfo";
import { fetchuserDetails } from "../../services/userService";
import Loading from "../utilities/Loading/Loading";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  // const [userDetails, setUserDetails] = useState(() => {
  //   const saved = localStorage.getItem("user");
  //   const initialValue = JSON.parse(saved);
  //   console.log("from init");
  //   return initialValue || { role: "both" };
  // });
  // const loggedUser = async () => {
  //   try {
  //     const userDetails = await fetchuserDetails();
  //     setUserDetails(userDetails);
  //     localStorage.setItem("user", JSON.stringify(userDetails));
  //     setLoading(false);
  //     console.log(userDetails);
  //   } catch (error) {
  //     console.error("Error fetching user details:", error.message);
  //   }
  // };
  // useEffect(() => {
  //   loggedUser();
  // }, []);

  //suppose userRole is int 'conductor:1 admin:2 both:0'
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <SideBar style={{ height: "100%" }} userRole={user ? user.role : ""}>
          <ToastContainer />
          {/* <Card sx={{ width: "100%" }}> */}
          {user && user.role === "admin" ? (
            <Dash />
          ) : (
            <BusInfo withSideBar={false} />
          )}
          {/* </Card> */}
        </SideBar>
      )}
    </>
  );
}

export default Dashboard;
