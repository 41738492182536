import SideBar from "../../components/sidebar/SideBar";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import { getUniqueBookingsByNIC } from "../../services/busServices";
import Loading from "../../components/utilities/Loading/Loading";

const Users = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false); // Added loading state

  const headCells = [
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "mobile_no", numeric: false, disablePadding: false, label: "Mobile Number" },
    { id: "nic", numeric: false, disablePadding: false, label: "NIC" },
    { id: "bookings", numeric: false, disablePadding: false, label: "No of Bookings" },
  ];

  const getUniqueBookingsByNICData = async () => {
    setLoading(true); // Set loading to true when starting to fetch data
    try {
      const res = await getUniqueBookingsByNIC();
      setRows(res);
    } catch (error) {
      console.error("Error fetching bookings nic details:", error.message);
    } finally {
      setLoading(false); // Set loading to false once data is fetched or an error occurs
    }
  };

  useEffect(() => {
    getUniqueBookingsByNICData();
  }, []);

  useEffect(() => {
    setFilteredRows(
      rows.filter(row =>
        Object.values(row).some(value =>
          value.toString().toLowerCase().includes(search.toLowerCase())
        )
      )
    );
  }, [search, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <SideBar userRole={user && user.role}>
      <ToastContainer />
      <Container sx={{ mt: 4 }}>
        <Card sx={{ p: 2, boxShadow: 3 }}>
          <Typography variant="h6" gutterBottom>
            Customers
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((cell) => (
                    <TableCell key={cell.id} sortDirection={false}>
                      <TableSortLabel>
                        {cell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length ? (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.mobile_no}</TableCell>
                        <TableCell>{row.nic}</TableCell>
                        <TableCell>{row.bookings}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* Loading Indicator */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loading/>
      </Backdrop>
    </SideBar>
  );
};

export default Users;
